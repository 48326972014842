<template>
  <b-modal
    id="modal-pdf"
    ref="modal"
    title="TRANSACCION VENTA"
    no-close-on-backdrop
    no-close-on-esc
    size="xl"
    scrollable
    body-bg-variant="dark"
    body-text-variant="light"
    ok-title="Realizar Factura"
    cancel-title="Cerrar"
    button-size="sm"
    @ok.prevent="realizarFactura(transaccion_id)"
    v-model="accionModal"
    @cancel.prevent="cerrarModal"
    @close="cerrarModal"
  >
    <vue-pdf-app
      v-show="pdf"
      :page-number="1"
      class="height-pdf"
      :pdf="pdf"
      file-name="FACTURA"
      :config="config"
      @pages-rendered="pagesRenderedHandler"
    >
      <template #toolbar-right-prepend="{}">
        <button
          @click="printPdf"
          type="button"
          title="Imprimir"
          style="padding: 8px 6px 0; font-size: 12px"
          class="prepend print hiddenMediumView vue-pdf-app-icon print-button"
        ></button>
      </template>
      <template #secondary-toolbar-prepend="{}">
        <button
          title="Imprimir"
          type="button"
          class="secondaryToolbarButton print vue-pdf-app-icon print-button"
          @click="printPdf"
        >
          <span>Imprimir</span>
        </button>
      </template>
    </vue-pdf-app>
    {{ transaccion_id }}
    <GenerarFactura ref="generarFactura" @listar="cerrarModal" />
  </b-modal>
</template>
<script>
import { BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "vue-pdf-app/dist/icons/main.css";
import printJS from "print-js";
import VentaServices from "@/modules/venta/services/index";
import GenerarFactura from "@/modules/venta/components/GenerarFactura.vue";
export default {
  components: {
    BModal,
    GenerarFactura,
  },
  props: ["accionModalPdf", "pdf", "pdfBase64", "transaccion_id"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      config: {
        sidebar: false,
        toolbar: {
          toolbarViewerLeft: {
            findbar: false,
          },
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            print: false,
            viewBookmark: false,
          },
        },
        secondaryToolbar: {
          secondaryPresentationMode: false,
          secondaryOpenFile: false,
          secondaryPrint: false,
          secondaryViewBookmark: false,
          firstPage: false,
          lastPage: false,
          pageRotateCw: false,
          pageRotateCcw: false,
          cursorSelectTool: false,
          cursorHandTool: false,
          scrollVertical: false,
          scrollHorizontal: false,
          scrollWrapped: false,
          spreadNone: false,
          spreadOdd: false,
          spreadEven: false,
        },
      },
      accionModal: false,
    };
  },
  watch: {
    accionModalPdf: function () {
      if (this.accionModalPdf) {
        this.accionModal = true;
      }
    },
  },
  methods: {
    async realizarFactura(id) {
      try {
        const response = await VentaServices.findOne(id);
        this.$bvModal
          .msgBoxConfirm("Confirme que desea Generar la Factura", {
            title: "GENERAR FACTURA ?",
            size: "sm",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: "Si, Facturar",
            cancelTitle: "Cancelar",
            cancelVariant: "outline-dark",
            hideHeaderClose: false,
          })
          .then((value) => {
            if (value) {
              this.$refs.generarFactura.realizarFactura(response.data);
            }
          });
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    cerrarModal() {
      this.$emit("cerrarModalPdf");
      this.accionModal = false;
    },
    printPdf() {
      printJS({
        printable: this.pdfBase64,
        type: "pdf",
        base64: true,
      });
    },
    pagesRenderedHandler(pdfApp) {
      // setTimeout(() => (pdfApp.pdfViewer.currentScaleValue = "page-width"));
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.height-pdf {
  height: calc(var(--vh, 1vh) * 100 - 12rem);
  .pdfViewer .page > * {
    height: 100% !important;
  }
}
#modal-pdf {
  .modal-xl {
    margin-left: auto;
    margin-right: auto;
  }
  .modal-body {
    padding: 0.5rem 0.5rem;
  }
}

@media (min-width: 800px) {
  #modal-pdf {
    .modal-xl {
      margin-left: 25%;
      margin-right: 25%;
    }
  }
}
</style>