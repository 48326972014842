<template>
  <div>
    <b-card title="Gestion de Cotizaciones">
      <b-card-text>
        <b-row>
          <b-col cols="12" md="6">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                v-if="$hascan('venta-registrar')"
                @click="$router.push('/cotizaciones/nueva-cotizacion')"
              >
                <feather-icon icon="SaveIcon" class="mr-50" />
                <span class="align-middle">NUEVA COTIZACION</span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="verPdf(item.id)"
                :disabled="buttonDisabled"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span class="align-middle">VER COTIZACION</span>
                </span>
                <feather-icon icon="EyeIcon" class="d-md-none" size="14" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="abrirModalVenta"
                :disabled="buttonDisabledVenta"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                  <span class="align-middle">VENDER COTIZACION</span>
                </span>
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="d-md-none"
                  size="14"
                />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="listar"
              >
                <span class="d-none d-md-block">
                  <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                  <span class="align-middle">RECARGAR</span>
                </span>
                <feather-icon
                  icon="RefreshCcwIcon"
                  class="d-md-none"
                  size="14"
                />
              </b-button>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <b-row style="justify-content: flex-end">
              <b-col cols="12" md="6" lg="3" class="mt-1 mt-md-0">
                <b-form-group>
                  <b-form-datepicker
                    v-model="fecha"
                    placeholder="YYYY-MM-DD"
                    size="sm"
                    class="form-control"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }"
                    @input="listar"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6" class="mt-1 mt-md-0">
                <b-form-group
                  class="mb-0"
                  label=""
                  label-align-sm="right"
                  label-for="filterInput"
                >
                  <b-form-input
                    v-model="searchQuery"
                    :formatter="textFormatter"
                    @input="updateSearchQuery"
                    type="search"
                    size="sm"
                    placeholder="Buscar.."
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text>
        <Listado
          ref="listadoRef"
          :items="items"
          :loading="loading"
          @selectItemVenta="selectItemVenta"
        />
      </b-card-text>
    </b-card>
    <ModalPdfTransaccion
      :pdf="pdf"
      :pdfBase64="pdfBase64"
      :accionModalPdf="accionModalPdf"
      @cerrarModalPdf="cerrarModalPdf"
    />

    <ModalCompraVenta
      :mostrado="mostradoModalCompraVentaCotizacion"
      titulo="VENDER COTIZACIÓN"
      @cerrar="cerrarModalCompraVenta"
    >
      <PuntoVentaLayout
        :cotizacion="item"
        @venta-registrada="cerrarModalCompraVenta"
      ></PuntoVentaLayout>
    </ModalCompraVenta>
  </div>
</template>

  <script>
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BPagination,
  BFormDatepicker,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Listado from "../views/Listado.vue";
import CotizacionServices from "../services/index";
import ModalPdfTransaccion from "@/modules/components/ModalPdfTransaccion.vue";
import ModalCompraVenta from "@/modules/components/ModalCompraVenta.vue";
import PuntoVentaLayout from "@/modules/venta/layouts/PuntoVentaLayout.vue";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    Listado,
    BRow,
    BCol,
    BPagination,
    ModalPdfTransaccion,
    ModalCompraVenta,
    PuntoVentaLayout,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      items: [],
      item: [],
      buttonDisabled: true,
      buttonDisabledVenta: true,
      searchQuery: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 1,
      from: 0,
      to: 0,
      total: 0,
      tipo_transaccion: "VENTA",
      // pdf
      accionModalPdf: false,
      pdf: "",
      pdfBase64: "",
      // venta
      mostradoModalCompraVentaCotizacion: false,
      fecha: "",
    };
  },
  created() {},
  methods: {
    // SERVICES
    async listar() {
      this.loading = true;
      try {
        const response = await CotizacionServices.get(
          this.mixing.sede_id,
          this.fecha
        );
        this.items = response.data;
        this.item = [];
        this.buttonDisabled = true;
        this.buttonDisabledVenta = true;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async verPdf(id) {
      try {
        const response = await CotizacionServices.findOnePdf(id);
        this.pdfBase64 = response.data.data;
        this.pdf =
          "data:application/pdf;base64," + response.data.data + "#toolbar=0";
        this.accionModalPdf = true;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    cerrarModalPdf() {
      this.accionModalPdf = false;
      this.pdf = "";
      this.pdfBase64 = "";
    },
    async eliminar(id) {
      try {
        const response = await VentaServices.destroy(id);
        this.$bvToast.toast("Se anulo correctamente.", {
          title: "OK!",
          variant: "success",
          solid: false,
        });
        this.listar();
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.listar();
    },
    selectItemVenta(item, bool) {
      this.item = item;
      this.buttonDisabled = bool;
      this.buttonDisabledVenta = this.item.estado != "Pendiente";
    },
    updateSearchQuery(val) {
      this.$refs.listadoRef.updateSearchQuery(val);
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    abrirModalVenta() {
      this.mostradoModalCompraVentaCotizacion = true;
    },
    cerrarModalCompraVenta() {
      this.listar();

      this.mostradoModalCompraVentaCotizacion = false;
    },
  },
  mounted() {
    this.fecha = this.mixing.hoy;
    this.listar();
  },
};
</script>

  <style lang="scss" scoped>
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
</style>
