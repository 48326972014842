<template>
  <b-modal
    id="modal-from-select-articulos"
    ref="modal"
    v-model="modalListaArticulo"
    title="BUSCAR ARTICULO"
    no-close-on-backdrop
    no-close-on-esc
    ok-title="Cerrar"
    button-size="sm"
    ok-variant="dark"
    cancel-variant="outline-dark"
    size="xl"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    ok-only
    hide-footer
  >
    <b-row class="mb-0-5">
      <b-col cols="12" md="7">
        <b-form-group
          label=""
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0.5"
        >
          <b-form-input
            id="idsearch"
            v-model="searchQuery"
            :formatter="textFormatter"
            onClick="this.select();"
            @input="updateSearchQuery"
            @keyup.enter="seleccionarArticulo()"
            size="sm"
            placeholder="Buscar Producto"
          />
        </b-form-group>
      </b-col>
      <b-col md="5" class="mt-1 mt-md-0">
        <b-form-group style="text-align: end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="dark"
            size="sm"
            v-if="$hascan('producto-registrar')"
            @click="$refs.modalFormRef.abrir('registrar', [])"
          >
            <feather-icon icon="SaveIcon" class="mr-50" />
            <span class="align-middle">NUEVO ARTICULO</span>
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xl="10" order="2" order-xl="1">
        <b-overlay :show="loading">
          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-balham ag-grid-table-modal mb-1"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            @grid-ready="onGridReady"
            :rowData="articuloItems"
            colResizeDefault="shift"
            rowSelection="single"
            :animateRows="false"
            :floatingFilter="false"
            :pagination="false"
            :rowClassRules="rowClassRules"
            @first-data-rendered="onFirstDataRendered"
            :alwaysShowVerticalScroll="true"
            :suppressPaginationPanel="false"
            @selection-changed="onSelectionChanged"
            @rowDoubleClicked="onRowDoubleClicked($event)"
            :navigateToNextCell="navigateToNextCell"
            @cell-key-down="onCellKeyDown"
          >
          </ag-grid-vue>
        </b-overlay>
      </b-col>
      <b-col cols="12" xl="2" order="1" order-xl="2">
        <div class="boxs boxs1 mb-1">
          <b-img
            v-if="imagenPreview"
            :src="imagenPreview"
            center
            thumbnail
            fluid
            class="rounded"
            alt="Sin imagen"
          />
          <b-img
            v-else
            v-bind="mainProps"
            rounded
            alt="Rounded image"
            class="d-inline-block mr-1 mb-1 border"
          />
        </div>
      </b-col>
    </b-row>
    <ModalFormProducto
      ref="modalFormRef"
      :item="item"
      @actualizarArticulo="actualizarArticulo"
    />
  </b-modal>
</template>
<script>
import PromocionesServices from "@/modules/administracion/promocion/services/index";
import {
  BButton,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BFormGroup,
  BFormInput,
  BCardText,
  BRow,
  BCol,
  BModal,
  VBModal,
  BOverlay,
  BImg,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { AgGridVue } from "ag-grid-vue";
import ModalFormProducto from "@/modules/administracion/producto/components/ModalFormProducto.vue";
export default {
  components: {
    BButton,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BFormGroup,
    BFormInput,
    AgGridVue,
    BCardText,
    BRow,
    BCol,
    BModal,
    VBModal,
    BOverlay,
    BImg,
    BPagination,
    ModalFormProducto,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      searchQuery: "",
      mainProps: {
        blank: true,
        width: 75,
        height: 75,
        class: "m1",
      },
      imagenPreview: "",
      item: [],
      loading: false,
      articuloItems: [],
      debounce: null,
      search: "",
      // grid
      gridOptions: {
        localeText: {
          noRowsToShow: "No hay filas para mostrar",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,

      gridApi: null,
      selectedRows: [],
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: true,
      },
      modalListaArticulo: false,
      columnDefs: [
        {
          headerName: "CODIGO",
          field: "codigo",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "DESCRIPCION",
          field: "descripcion",
          filter: false,
          autoHeight: true,
          wrapText: true,
          minWidth: 200,
        },
        {
          headerName: "PRECIO",
          field: "precio_unitario",
          type: "numericColumn",
          filter: false,
          minWidth: 90,
          maxWidth: 90,
        },
        {
          headerName: "COSTO",
          field: "costo",
          type: "numericColumn",
          filter: false,
          minWidth: 90,
          maxWidth: 90,
          hide: !this.ocultarCostoColumna,
        },
        {
          headerName: "PROMOCION",
          field: "es_porcentual",
          cellRenderer: function (param) {
            return param.data.es_porcentual
              ? `<span class="badge badge-primary">- ${param.data.valor_descuento} %</span>`
              : `<span class="badge badge-primary">- ${param.data.valor_descuento}</span>`;
          },

          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "DESCUENTO",
          field: "descuento",
          cellRenderer: function (param) {
            return parseFloat(param.data.descuento).toFixed(2);
          },
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "CANTIDAD",
          cellRenderer: function (param) {
            if (param.data.es_servicio == 1) {
              return '<span class="badge badge-warning">SERV.</span>';
            } else {
              return parseFloat(param.data.stock).toFixed(2);
            }
          },
          valueFormatter: numberFormatter,
          // field: "stock",
          type: "numericColumn",
          filter: false,
          minWidth: 90,
          maxWidth: 90,
        },
        {
          headerName: "CATEGORIA",
          field: "categoria",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "MARCA",
          field: "marca",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "UNIDAD",
          field: "unidad",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
      ],
      rowClassRules: { "row-red": "data.estado === 0" },
    };
  },
  props: {
    ocultarCostoColumna: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 0;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  created: function () {
    window.addEventListener("keyup", this.onkey);
  },
  destroyed: function () {
    window.removeEventListener("keyup", this.onkey);
  },
  methods: {
    onkey(event) {
      if (event.key == "Escape") {
        if (this.modalListaArticulo) {
          document.getElementById("idsearch").focus();
          document.getElementById("idsearch").select();
        }
      }
    },
    onGridReady(params) {
      params.api.sizeColumnsToFit();
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    navigateToNextCell(params) {
      let suggestedNextCell = params.nextCellPosition;
      let KEY_UP = "ArrowUp";
      let KEY_DOWN = "ArrowDown";

      let noUpOrDownKeyPressed =
        params.event.key !== KEY_DOWN && params.event.key !== KEY_UP;
      if (noUpOrDownKeyPressed || !suggestedNextCell) {
        return suggestedNextCell;
      }
      this.gridApi.forEachNode(function (node) {
        if (node.rowIndex === suggestedNextCell.rowIndex) {
          node.setSelected(true);
        }
      });
      return suggestedNextCell;
    },
    onSelectionChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      if (selectedRows != "") {
        this.imagenPreview = selectedRows[0].imagen;
      } else {
        this.imagenPreview = "";
      }
    },
    onCellKeyDown(params) {
      if (params.data) {
        if (params.event.key == "Enter") {
          this.$emit("selectItemArticulo", params.data, true);
          this.gridOptions.api.deselectAll();
          this.modalListaArticulo = false;
        }
      }
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.sizeColumnsToFit();
      this.gridApi.refreshCells();
    },
    onRowDoubleClicked(params) {
      if (params.data) {
        this.$emit("selectItemArticulo", params.data, true);
        this.gridOptions.api.deselectAll();
        this.modalListaArticulo = false;
      }
    },
    seleccionarArticulo() {
      this.gridApi.setFocusedCell(0, "codigo");
      this.gridApi.sizeColumnsToFit();
      this.gridApi.forEachNode((node) =>
        node.rowIndex ? 0 : node.setSelected(true)
      );
    },
    async listar(promoId) {
      this.loading = true;
      try {
        const response = await PromocionesServices.findAllProductos(
          this.mixing.sede_id,
          promoId
        );
        this.articuloItems = response.data;
        this.loading = false;
        this.updateSearchQuery(this.searchQuery);
      } catch (err) {
        this.loading = false;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    focusBuscarArticulo(promoId) {
      this.modalListaArticulo = true;
      this.listar(promoId);
      setTimeout(() => {
        document.getElementById("idsearch").focus();
        document.getElementById("idsearch").select();
      }, 100);
    },
    focusBuscarArticuloNuevo() {
      this.articuloItems = [];
      this.search = "";
    },
    cerrarModal() {
      this.modalListaArticulo = false;
      this.gridOptions.api.deselectAll();
    },
    textFormatter(value) {
      return value.toUpperCase();
    },

    actualizarArticulo(articulo) {
      this.listar();
      setTimeout(() => {
        document.getElementById("idsearch").focus();
        document.getElementById("idsearch").select();
        this.search = articulo.descripcion;
        this.articuloSearchQuery(this.search);
      }, 300);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
function numberFormatter(params) {
  return Number(params.value).toFixed(2);
}
</script>
<style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
.ag-theme-balham .row-red {
  color: #ea5455 !important;
}
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
.boxs {
  height: 14rem;
}
.boxs img {
  width: 100%;
  height: 100%;
}
.boxs1 img {
  object-fit: contain;
}
</style>