<template>
  <b-modal
    id="modal-pago"
    ref="modal"
    v-model="modalPago"
    title="PAGAR"
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
    scrollable
    ok-title="Aceptar"
    cancel-title="Cancelar"
    button-size="sm"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <validation-observer ref="rulesPagos">
      <b-form>
        <div class="text-center">
          <h1>TOTAL A PAGAR</h1>
          <h1 class="text-primary" style="font-size: 4rem">Bs {{ total }}</h1>
        </div>
        <hr />
        <b-row class="mb-0-5">
          <b-col cols="12" md="6" class="mb-0-5">
            <validation-provider
              #default="{ errors }"
              name="metodo_pago"
              rules="required"
            >
              <b-form-group
                label="METODO DE PAGO:"
                label-for="metodo_pago"
                :state="errors.length > 0 ? false : null"
                label-cols="12"
                label-cols-lg="4"
              >
                <b-form-select
                  id="metodo_pago"
                  size="sm"
                  v-model="codigoMetodoPago"
                  value-field="codigo"
                  text-field="descripcion"
                  :options="metodoPagoItems"
                  @input="obtenerFormaPago"
                  :state="errors.length > 0 ? false : null"
                >
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- WILL -->
          <b-col cols="12" md="6" class="mb-0-5">
            <!-- :rules="ruleTarjeta" -->
            <validation-provider
              #default="{ errors }"
              name="Tarjeta de crédito"
              :rules="ruleTarjeta"
            >
              <b-form-group
                label="NRO. TARJETA:"
                label-cols="12"
                label-cols-lg="4"
              >
                <b-form-input
                  :disabled="disableTarjeta"
                  v-model="nroTarjeta"
                  maxlength="16"
                  size="sm"
                  :state="errors.length > 0 ? false : null"
                  onClick="this.select();"
                  placeholder="0000 0000 0000 0000"
                  @input="tarjetaCredito"
                  :class="{ 'is-invalid': !!errors.length }"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <hr />
        <div>
          <b-row class="mb-0-5">
            <b-col cols="12" md="3">
              <b-form-group label="EFECTIVO" class="text-center">
                <b-form-input
                  style="text-align: center"
                  onClick="this.select();"
                  :formatter="efectivoNumberFormatter"
                  :value="efectivo"
                  :disabled="disabledEfectivo"
                  lazy-formatter
                  size="sm"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <b-form-group label="TARJETA" class="text-center">
                <b-form-input
                  style="text-align: center"
                  onClick="this.select();"
                  :formatter="tarjetaNumberFormatter"
                  :value="tarjeta"
                  :disabled="disabledTarjeta"
                  lazy-formatter
                  size="sm"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <b-form-group label="CREDITO" class="text-center">
                <b-form-input
                  style="text-align: center"
                  onClick="this.select();"
                  v-model="credito"
                  :value="$emit('update:credito', totalCredito)"
                  :disabled="disabledCredito"
                  size="sm"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <b-form-group label="TRANSACCION BANCARIA" class="text-center">
                <b-form-input
                  style="text-align: center"
                  onClick="this.select();"
                  :formatter="qrNumberFormatter"
                  :value="transferencia_bancaria"
                  :disabled="disabledQr"
                  lazy-formatter
                  size="sm"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="justify-content-end">
            <b-col cols="12" md="6">
              <b-form-group
                label-cols="12"
                label-cols-lg="6"
                :label-class="cambio < 0 ? 'text-danger' : 'text-primary'"
                label-align-md="right"
                label="CAMBIO"
                class="text-center"
              >
                <b-form-input
                  style="text-align: center"
                  :class="cambio < 0 ? 'text-danger' : 'text-primary'"
                  v-model="cambio"
                  :value="$emit('update:cambio', totalCambio)"
                  disabled
                  size="sm"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <hr
          v-if="
            disabledCuentas &&
            (metodo_pago.includes('TARJETA') ||
              metodo_pago.includes('EFECTIVO') ||
              metodo_pago.includes('TRANSFERENCIA BANCARIA'))
          "
        />

        <b-row v-if="disabledCuentasTransaccion" class="mb-0-5">
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              name="banco"
              rules="required|integer"
            >
              <b-form-group
                label="CUENTA"
                label-cols="12"
                label-cols-lg="4"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-select
                  id="cuenta"
                  size="sm"
                  :value="banco_id"
                  value-field="id"
                  text-field="descripcion"
                  :options="bancoItems"
                  @input="obtenerBanco"
                  :state="errors.length > 0 ? false : null"
                >
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              name="comrpobante de pago"
              rules="required"
            >
              <b-form-group
                label="COMPRO. DE PAGO:"
                label-cols="12"
                label-cols-lg="4"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  :formatter="comprobanteFormatter"
                  :value="comprobante_pago"
                  lazy-formatter
                  size="sm"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
  <script>
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import MetodoPagoServices from "@/modules/configuracion-sin/services/metodoPago";
import BancoServices from "@/modules/configuracion/banco/services/index";
export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: [
    "total",
    "tipo",
    "efectivo",
    "credito",
    "tarjeta",
    "transferencia_bancaria",
    "cambio",
    "metodo_pago",
    "nro_tarjeta",
    // "codigo_metodo_pago",
    "metodo_pago_id",
    "banco_id",
    "comprobante_pago",
    "recibo",
  ],
  data() {
    return {
      modalPago: false,
      codigoMetodoPago: "",
      disabledEfectivo: true,
      disabledTarjeta: true,
      disabledCredito: true,
      disabledQr: true,
      metodoPagoItems: [],
      ruleTarjeta: "",
      disableTarjeta: true,
      errors: [],
      nroTarjeta: "",

      disabledCuentas: true,
      disabledCuentasTransaccion: true,
      // recibo: "",
      // comprobante_pago: "",
      // banco_id: null,
      bancoItems: [],
    };
  },
  watch: {
    metodo_pago_id: function () {
      if (this.metodo_pago_id == 1) {
        this.$emit("update:efectivo", parseFloat(this.total).toFixed(2));
      }
      if (this.metodo_pago_id == 2) {
        this.$emit("update:tarjeta", parseFloat(this.total).toFixed(2));
      }
      if (this.metodo_pago_id == 4) {
        this.$emit(
          "update:transferencia_bancaria",
          parseFloat(this.total).toFixed(2)
        );
      }
      this.$emit("update:banco_id", null);
      this.$emit("update:comprobante_pago", "");
      this.$emit("update:recibo", "");
    },
  },
  computed: {
    totalCredito() {
      if (this.metodo_pago.includes("PAGO POSTERIOR")) {
        return (
          parseFloat(this.total) -
          parseFloat(this.efectivo) -
          parseFloat(this.tarjeta) -
          parseFloat(this.transferencia_bancaria)
        ).toFixed(2);
      }
      return "0.00";
    },
    totalCambio() {
      return (
        parseFloat(this.efectivo) +
        parseFloat(this.tarjeta) +
        parseFloat(this.transferencia_bancaria) +
        parseFloat(this.credito) -
        parseFloat(this.total)
      ).toFixed(2);
    },
  },
  methods: {
    comprobanteFormatter(value) {
      this.$emit("update:comprobante_pago", value);
      return value;
    },
    reciboFormatter(value) {
      this.$emit("update:recibo", `${value}`);
      return value;
    },
    obtenerBanco(value) {
      this.$emit("update:banco_id", value);
    },
    cerrarModal() {
      this.$emit("update:metodo_pago", "");
      this.$emit("update:metodo_pago_id", "");
      this.modalPago = false;
    },
    realizarPago() {
      this.modalPago = false;
    },
    async abrirModal() {
      await this.getMetodosPago();
      this.$emit("update:efectivo", parseFloat(this.total));
      this.$emit("update:credito", "0.00");
      this.$emit("update:tarjeta", "0.00");
      this.$emit("update:transferencia_bancaria", "0.00");
      this.$emit("update:metodo_pago", "EFECTIVO");
      this.$emit("update:nro_tarjeta", "");
      this.$emit("update:metodo_pago_id", 1);
      this.$emit("update:banco_id", null);
      this.$emit("update:comprobante_pago", "");
      this.$emit("update:recibo", "");
      this.codigoMetodoPago = this.metodoPagoItems[0].codigo;
      await this.obtenerFormaPago(this.codigoMetodoPago);
      if (this.codigoMetodoPago.descripcion.includes("EFECTIVO")) {
        this.$emit("update:efectivo", parseFloat(this.total).toFixed(2));
      }
      this.getBanco();
      this.nroTarjeta = "";
      // this.banco_id = null;
      // this.comprobante_pago = "";
      // this.recibo = "";
      this.modalPago = true;
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
    efectivoNumberFormatter(value) {
      this.$emit("update:efectivo", (value * 1).toFixed(2));
      return (value * 1).toFixed(2);
    },
    tarjetaNumberFormatter(value) {
      this.$emit("update:tarjeta", (value * 1).toFixed(2));
      return (value * 1).toFixed(2);
    },
    qrNumberFormatter(value) {
      this.$emit("update:transferencia_bancaria", (value * 1).toFixed(2));
      return (value * 1).toFixed(2);
    },
    async getMetodosPago() {
      try {
        const response = await MetodoPagoServices.get({ estado: 1 });
        const metodoPagoItems = response.data.map((g) => ({
          descripcion: g.descripcion,
          codigo: {
            codigo: g.id,
            descripcion: g.descripcion,
          },
        }));
        this.metodoPagoItems = metodoPagoItems.filter(
          (g) => g.descripcion != ["OTROS"]
        );
      } catch (err) {
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    obtenerFormaPago(value) {
      if (value == null) {
        this.$emit("update:metodo_pago_id", null);
        this.$emit("update:nro_tarjeta", null);
        this.nroTarjeta = null;
        this.$emit("update:metodo_pago", null);
        this.ruleTarjeta = "";
        this.disableTarjeta = true;
        this.$emit("update:efectivo", "0.00");
        this.$emit("update:tarjeta", "0.00");
        this.$emit("update:transferencia_bancaria", "0.00");
        this.disabledEfectivo = true;
        this.disabledTarjeta = true;
        this.disabledCredito = true;
        this.disabledQr = true;
      } else {
        this.nroTarjeta = null;
        this.$emit("update:nro_tarjeta", null);
        this.$emit("update:metodo_pago_id", value.codigo);
        this.$emit("update:metodo_pago", value.descripcion);
        this.$emit("update:efectivo", "0.00");
        this.$emit("update:tarjeta", "0.00");
        this.$emit("update:transferencia_bancaria", "0.00");
        if (value.descripcion.includes("TARJETA")) {
          this.disabledTarjeta = false;
          this.ruleTarjeta = "required|min:16|max:16";
          this.disableTarjeta = false;
        } else {
          this.ruleTarjeta = "";
          this.disableTarjeta = true;
          this.disabledTarjeta = true;
        }
        if (value.descripcion.includes("EFECTIVO")) {
          this.disabledEfectivo = false;
        } else {
          this.disabledEfectivo = true;
        }
        if (value.descripcion.includes("PAGO POSTERIOR")) {
          this.disabledCredito = true;
        } else {
          this.disabledCredito = true;
        }
        if (value.descripcion.includes("TRANSFERENCIA BANCARIA")) {
          this.disabledQr = false;
        } else {
          this.disabledQr = true;
        }
        if (value.descripcion.includes("PAGO POSTERIOR")) {
          if (value.descripcion.includes("TRANSFERENCIA BANCARIA")) {
            this.disabledCuentasTransaccion = true;
          } else {
            this.disabledCuentasTransaccion = false;
          }
          this.disabledCuentas = true;
        } else {
          this.disabledCuentas = false;
          this.disabledCuentasTransaccion = false;
        }
      }
    },
    tarjetaCredito(value) {
      if (value.length == 4) {
        this.nroTarjeta = value + "00000000";
        this.$emit("update:nro_tarjeta", this.nroTarjeta);
      }
    },
    async getBanco() {
      try {
        const response = await BancoServices.get({});
        this.bancoItems = response.data;
      } catch (err) {
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    validar() {
      this.$refs.rulesPagos.validate().then((success) => {
        if (success) {
          // if(parseFloat(this.efectivo) + parseFloat(this.tarjeta) + parseFloat(this.qr) + parseFloat(this.credito) == parseFloat(this.total))
          if (
            parseFloat(this.efectivo) +
              parseFloat(this.tarjeta) +
              parseFloat(this.transferencia_bancaria) +
              parseFloat(this.credito) >=
            parseFloat(this.total)
          ) {
            this.modalPago = false;
            this.$emit("realizarTransaccion");
          } else {
            this.$bvToast.toast(
              " ERROR LOS PAGOS NO PUEDEN SER MENOR AL TOTAL ",
              {
                title: "ERROR!",
                variant: "danger",
                toaster: "b-toaster-top-center",
                solid: false,
              }
            );
          }
        }
      });
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
</style>